<template>
  <v-app style="background-color:#f8f9fb" >


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 365 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilterDownload" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        Em Manutenção
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilterDownload = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


    <v-container style="background-color:#f8f9fb;" >
      <v-col cols="12">
        <filtro-component :opcoes="opcoes" :produtos="tipos_funerarios" :modos="modos"  :tipos="tipos" :calendar="'mes'"
        @filtrar="filterData" :title_opcao="'Campanha'" :campos="'N'"
          ></filtro-component>
      </v-col>
    </v-container>


  <v-container style="background-color:#eee"  
    id="financeiro"
    fluid
    tag="section">
   
    <v-row>
    


    <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card elevation="1"
          color="orange"
          icon="mdi-handshake"
          subIcon="mdi-information"
          subIconE="mdi-information"
          subIconColor="blue"
          title="Recebido com Acordos no Período"
          sub-icon-color="black"
          :value="formatarMoeda(this.recebido_acordo)"
          :valuex="this.bool_03"
          :sub-text="'Total de Contratos: '+ this.qtd_recebido_acordo"
        
          />       
     
    </v-col>


    <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card elevation="1"
          color="orange"
          icon="mdi-handshake"
          subIcon="mdi-information"
          subIconE="mdi-information"

          subIconColor="blue"
          title="Acordos Criado no Período"
          sub-icon-color="black"
          :value="formatarMoeda(this.criado_acordo)"
          :valuex="this.bool_02"
          :sub-text="'Total de Contratos: ' + this.qtd_criado_acordo"
        
          />       
     
    </v-col>


    <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card elevation="1"
          color="orange"
          icon="mdi-handshake"
          subIcon="mdi-information"
          subIconE="mdi-information"
          subIconColor="blue"
          title="Quantidade Cancelada no Período"
          sub-icon-color="black"
          :value="(this.qtd_cancelados_acordos)"
          :valuex="this.bool_02"
          :sub-text="''"
          />       
     
    </v-col>

    </v-row>
    <v-row>
    
    <v-col
    cols="4">

        <base-material-card
        icon="mdi-account-group"
        title="Acordos Cancelado no Período "
        color="red" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table class="elevation-0"
              :headers="ranking_header_cancelados"
              :items="ranking_cancelados"
              :items-per-page="20" 
              items-per-page-text="Linhas por página">
      

                        <template v-slot:item="row">
                          
                          <tr :class="{'gray-row': row.index % 2 !== 0}">
                            <td style="width: 80%;font-size:14px;">{{row.item.usuario}} </td>
                           
                            <td style="width: 8%;font-size:14px;">{{row.item.qtdAcordo}} </td>

                             <td style="width:10%;font-size:14px;">
                              <v-chip style="font-size:10px;"
                              :color="getStatus(row.item.empresa)"
                              text-color="white">
                                  {{row.item.empresa}}                    
                                  
                              </v-chip>  
                            </td>

                            
                          </tr>
                      </template>
              </v-data-table>


          <br/>
          </base-material-card>
  
    </v-col>

  


    
    <v-col
    cols="8">

        <base-material-card
        icon="mdi-account-group"
        title="Lista de Acordos por Usuário (Criação do Acordo)"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table
              :headers="ranking_header"
              :items="ranking"
              :items-per-page="20" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      

                        <template v-slot:item="row">
                          
                          <tr :class="{'gray-row': row.index % 2 !== 0}">
                            <td style="width: 22%;font-size:14px;">{{row.item.usuario}} </td>
                           

                            <td style="width: 12%;font-size:14px;"> 
                           
                              {{
                                 parseFloat(row.item.valorTotal).toLocaleString('pt-BR', {
                                   style: 'currency',
                                   currency: 'BRL'
                                 })
                               }}      
                            </td>
                            <td style="width:12%;font-size:14px;">
                              

                              {{
                                 parseFloat(row.item.valorRecebido).toLocaleString('pt-BR', {
                                   style: 'currency',
                                   currency: 'BRL'
                                 })
                               }}     
                              
                            </td>
                            <td style="width: 6%;font-size:14px;">{{row.item.qtdAcordo}} </td>
                            <td style="width: 15%;font-size:14px;">{{ parseFloat(row.item.percentualRecebido).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} %</td>

                          <td style="width:10%;font-size:14px;">
                              <v-chip 
                              :color="getStatus(row.item.empresa)"
                              text-color="white">
                                  {{row.item.empresa}}                    
                                  
                              </v-chip>  
                            </td>
                            
                          </tr>
                      </template>
              </v-data-table>


    <br/>

  

          </base-material-card>
  
    </v-col>

  

  </v-row>


  </v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import FiltroComponent from "./components/FiltroGeral.vue"; // Certifique-se de que o caminho esteja correto
  import Acordos from '../../services/acordos' 
  import { ContentLoader } from 'vue-content-loader'

  export default {
    name: 'Obitos',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,

    },

    created() {
      this.selectedStartDate = this.getFirstDayOfMonth(),
      this.selectedEndDate =  this.formatDateBR(new Date())

      this.selectedStartDate2 = this.getFirstDayOfMonth(),
      this.selectedEndDate2 =  this.formatDateBR(new Date())
    },


  

    
    mounted() {
     this.carregarLoad(),
     this.dashboard();
     this.handleFilterChange(this.selectMode, this.selectedStartDate, this.selectedEndDate, this.selectedStartDate2, this.selectedEndDate2);
    },
   

    data () {
      return {
        ranking_header: [
          { text: 'Usuário', value: 'usuario' },
          { text: 'Valor Total', value: 'valorTotal' },
          { text: 'Valor Recebido', value: 'valorRecebido' },
          { text: 'Qtd', value: 'qtd' },
          { text: '%', value: 'percentualRecebido' },
          { text: 'Empresa', value: 'empresa' },

        ],

        ranking: [],

        ranking_header_cancelados: [
          { text: 'Usuário', value: 'usuario' },
          { text: 'Qtd', value: 'qtdAcordo' },
          { text: 'Empresa', value: 'empresa' },

        ],

        ranking_cancelados: [],


        produto: 'Todos',
         bool_03 : true,
         bool_02: true,
     

         subTextAddDynamic: "Total Recebido:",
     

         recebido_acordo:0,
         qtd_recebido_acordo:0,
         qtd_criado_acordo:0,
         qtd_cancelados_acordos: 0,
         criado_acordo: 0,
      
         animationInterval: null, // Intervalo para controlar a animação

          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          opcoes: ["Todas","Campanha 50 Anos"],
          selectMode: "Vencimento",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          modos: ["Período"],
          modos2: ["SIM", "NÃO"],
          campos: 'N',
          categoria: [],
          showFilter: false,
          showFilterDownload: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos", "Planos", "Planos Florais", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos", "Serviços Cemiteriais"],
          tipos_funerarios: [
                "Todos",
                "COMPLETO",
                "CONCESSÃO DE COLUMBÁRIO",
                "CONCESSÃO DE JAZIGOS",
                "CONCESSÃO DE OSSÁRIO",
                "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS",
                "URNAS CINERARIAS",
                "VENDA DE COLUMBÁRIO",
                "VENDA DE JAZIGOS - IMEDIATO",
                "VENDA DE JAZIGOS - PREVENTIVO",
                "VENDA DE JAZIGOS - TRANSITÓRIO"
              ],

       

           lista_nome_empresa:[],
      
      }
    },

   
    methods: {
     
      
      

        isDateValid(dateString) {
          // Verifica se a string é uma data válida
          const date = new Date(dateString);
          return !isNaN(date.getTime());
        },
        areDatesValid(dates) {
          // Verifica se todas as datas na matriz são válidas
          return dates.every(dateString => {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
          });
        },

        handleFilterChange(selectedFilter, dataI, dataF, DataRI, DataRF) {

         

          if (selectedFilter === 'Vencimento') {
            this.subTextAddDynamic = 'Total Recebido: ';
          } else if (selectedFilter != 'Vencimento') {

            if (
              converteDataParaFormatoISO(DataRI) <= converteDataParaFormatoISO(dataI) &&
              converteDataParaFormatoISO(DataRF) > converteDataParaFormatoISO(dataF)
              ) {
                this.subTextAddDynamic = 'Total Recebido com Acréscimo: ';
              }else{
                this.subTextAddDynamic = 'Total Recebido: ';

              }
          }

        },

      
      filterData(valores) {
        
          this.carregarLoad(),
          this.isLoading = true;
          let nova_consulta = false;

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;

          this.selectMode = "Vencimento";


          this.handleFilterChange(valores.selectMode, valores.selectedStartDate, valores.selectedEndDate, valores.selectedStartDate2, valores.selectedEndDate2);
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.selectedStartDate2 = valores.selectedStartDate2;
          this.selectedEndDate2 = valores.selectedEndDate2;
          this.selectedStartDate3 = valores.selectedStartDate3;
          this.selectedEndDate3 = valores.selectedEndDate3;
          this.produto = valores.selectedProduto;
          //this.gf01();
          this.dashboard();
          this.gf01();
          this.gf02();
      },

      
      filtrarTiposFunerarios(tipoFunerario) {
        const tiposPermitidos = "Todos";
        if (this.tipoFunerario === "Jazigo") {
           tiposPermitidos = ["CONCESSÃO DE COLUMBÁRIO", "CONCESSÃO DE JAZIGOS", "CONCESSÃO DE OSSÁRIO", "VENDA DE COLUMBÁRIO", "VENDA DE JAZIGOS - IMEDIATO", "VENDA DE JAZIGOS - PREVENTIVO", "VENDA DE JAZIGOS - TRANSITÓRIO"];
        }
        if (this.tipoFunerario === "Plano") {
           tiposPermitidos = ["COMPLETO",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS"];
        }

        if (this.tipoFunerario === "Cremação") {
           tiposPermitidos = [ "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "URNAS CINERARIAS"];
        }

        if (this.tipoFunerario === "Todos" ||  this.tipoFunerario ===  "Funerária" || this.tipoFunerario ===  "Serviços Administrativos" || this.tipoFunerario ===  "Serviços Cemiteriais") {
           tiposPermitidos = [ "Todos"];
        }

        if (tiposPermitidos.includes(tipoFunerario)) {
          return true; // Mantém apenas os tipos permitidos
        } else {
          return false; // Remove os tipos não permitidos
        }
      },
     
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },

        startAnimation(targetValue) {
            this.animationInterval = setInterval(() => {
              if (this.animatedValue < targetValue) {
                this.animatedValue += 1; // Atualiza o valor animado
              } else {
                clearInterval(this.animationInterval); // Interrompe a animação quando atingir o valor desejado
              }
            }, 10); // Intervalo em milissegundos entre cada atualização
          },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },



      carregarLoad(){

         this.bool_03 = true
         this.bool_03 = true;

      },

      async dashboard() {


              this.selectMode = "Vencimento";
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              const formattedStartDate2 = converteDataParaFormatoISO(this.selectedStartDate2);
              const formattedEndDate2 = converteDataParaFormatoISO(this.selectedEndDate2);

              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 365) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        return 
                      }
                   
                    Acordos.total_recebimento_acordo(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.recebido_acordo = response.data;                                
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_03 = false;
                          })  

                    Acordos.total_cadastrado_acordo(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.criado_acordo = response.data;                                
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_02 = false;
                        })  

                    Acordos.ranking( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany,  this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {
                          if(response.status === 200){
                            this.ranking = response.data;

                          } 


                        }).catch(e => {    
                        }).finally(() => {
                         
                      });

                    Acordos.ranking_cancelados( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany,  this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {
                       
                        
                        if(response.status === 200){
                            this.ranking_cancelados = response.data;

                          } 


                        }).catch(e => {    
                        }).finally(() => {
                         
                      });


                  
                    Acordos.qtd_recebimento_acordo(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.qtd_recebido_acordo = response.data;                                
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                            });
                  
                    Acordos.qtd_criado_acordo(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.qtd_criado_acordo = response.data;                                
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                            });
                    
                    Acordos.qtd_cancelados_acordos(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                      if(response.status === 200){
                              this.qtd_cancelados_acordos = response.data;                                
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                            });
                            
                       
                            
                 


                 qtd_recebimento_acordo

      },
   
    

      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },


    getStatus (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },


      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


        clear () {
          this.$refs.form.reset();
        },

    },
  }

   
  function formatMoeda(value) {
                      // Use o método toLocaleString com a opção style: 'currency' para formatar como moeda

                      if (value === undefined || value === null) {
                          return 'R$ 0,00'; // Ou você pode retornar 0 como número, se preferir.
                        }

                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                      //return 0
                      //return value
                  }

 
  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }



</script>

<style scoped>
  .gray-row {
      background-color: #e7f1ff; /* ou qualquer outra cor cinza que você preferir */

    }

  
  </style>